import axiosClient from "./axiosClient";

const orderApi = {
  async getMyOrder() {
    const url = `/orders/my-orders`;
    return axiosClient.get(url);
  },
  async getOrderDetails(id) {
    const url = `/orders/${id}`;
    return axiosClient.get(url);
  },
  async checkoutOrder(data) {
    const url = `/orders/check-out1`;
    return axiosClient.post(url,data);
  },
  async getFeeShip(data) {
    const url = "orders/fee";
    return axiosClient.post(url, data);
  },
  async orderCountPrice(data) {
    const url = `/orders/count-price`;
    return axiosClient.post(url,data);
  }
};

export default orderApi;
