import axiosClient from "./axiosClient";

const supplierApi = {
    //
   async getAll(page,size){
       const url = `/stores/all-paging?page_index=${page}&page_size=${size}`
       return axiosClient.get(url)
   }

}

export default supplierApi