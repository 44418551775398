import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import addressApi from "../../api/addressApi";
import { toast } from "react-toastify";

export const getCity = createAsyncThunk("address/getCityAddress", async () => {
  const city = await addressApi.getCity();
  return city;
});

export const getDistrict = createAsyncThunk(
  "address/getDistrictAddress",
  async (id) => {
    const district = await addressApi.getDistricts(id);
    return district;
  }
);
export const deleteAddress = createAsyncThunk(
  "address/deleteAddress",
  async (id) => {
    const deleteMyAddress = await addressApi.removeAddress(id);
    if (deleteMyAddress.status === 200) {
      toast.success("Xóa địa chỉ thành công");
      const listAddress = await addressApi.getMyAddress();
      return listAddress;
    } else {
      toast.error("Xóa địa chỉ thất bại");
    }
  }
);
export const getWard = createAsyncThunk(
  "address/getWardAddress",
  async (id) => {
    const ward = await addressApi.getWard(id);
    return ward;
  }
);
export const getListAddress = createAsyncThunk(
  "address/getListAddress",
  async () => {
    const listAddress = await addressApi.getMyAddress();
    return listAddress;
  }
);

export const createNewAddress = createAsyncThunk(
  "address/createNewAddress",
  async (data) => {
    const newAddress = await addressApi.createNewAddress(data);
    if (newAddress.status === 200) {
      const listAddress = await addressApi.getMyAddress();
      return listAddress;
    }
  }
);

const addressSlice = createSlice({
  name: "address",
  initialState: {
    city: [],
    loadingproduct: false,
    district: [],
    ward: [],
    listAddress: [],
    addAddressSelect: [],
  },
  reducers: {
    setAddressSelect: (state, { payload }) => {
      state.addAddressSelect = payload;
    },
  },
  extraReducers: {
    [getCity.pending]: (state) => {
      state.loadingproduct = true;
    },
    [getCity.rejected]: (state) => {
      state.loadingproduct = false;
    },
    [getCity.fulfilled]: (state, action) => {
      state.loading = false;
      state.city = action.payload.data;
    },
    [deleteAddress.pending]: (state) => {
      state.loadingproduct = true;
    },
    [deleteAddress.rejected]: (state) => {
      state.loadingproduct = false;
    },
    [deleteAddress.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [getDistrict.pending]: (state) => {
      state.loadingproduct = true;
    },
    [getDistrict.rejected]: (state) => {
      state.loadingproduct = false;
    },
    [getDistrict.fulfilled]: (state, action) => {
      state.loadingproduct = false;
      state.district = action.payload.data;
      // state.total_page = action.payload.data.total_pages;
    },
    [getWard.pending]: (state) => {
      state.loadingproduct = true;
    },
    [getWard.rejected]: (state) => {
      state.loadingproduct = false;
    },
    [getWard.fulfilled]: (state, action) => {
      state.loadingproduct = false;
      state.ward = action.payload.data;
      // state.total_page = action.payload.data.total_pages;
    },

    [getListAddress.pending]: (state) => {
      state.loadingproduct = true;
    },
    [getListAddress.rejected]: (state) => {
      state.loadingproduct = false;
    },
    [getListAddress.fulfilled]: (state, action) => {
      state.loadingproduct = false;
      state.listAddress = action.payload.data;
      // state.total_page = action.payload.data.total_pages;
    },

    [createNewAddress.pending]: (state) => {},

    [createNewAddress.fulfilled]: (state, action) => {
      state.listAddress = action.payload.data;
    },

    [createNewAddress.rejected]: (state, action) => {
    },
    [deleteAddress.fulfilled]: (state, action) => {
      state.listAddress = action.payload.data;
    },
  },
});

export const { setAddressSelect } = addressSlice.actions;

export default addressSlice;
