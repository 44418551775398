import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import storeApi from "../../api/storeApi";

export const getDetailStore = createAsyncThunk(
  "store/storegetDetail",
  async (id) => {
    const detailStore = await storeApi.getDetail(id);
    return detailStore;
  }
);
export const getProductStore = createAsyncThunk(
  "store/storegetProduct",
  async (data) => {
    const { id, page, size } = data;
    const productStore = await storeApi.getProduct(id, page, size);
    return productStore;
  }
);

export const getMyStore = createAsyncThunk("store/getMyStore", async () => {
  const myStore = await storeApi.getMyStore();
  return myStore;
});

export const storeGetProduct = createAsyncThunk(
  "store/getProductForStore",
  async (id, thunkApi) => {
    const myStore = await storeApi.getMyStore();
    if (myStore.status === 200) {
      const myProduct = await storeApi.storeGetProduct(myStore.data.id);
      return myProduct;
    }
  }
);

export const storeDeleteProduct = createAsyncThunk(
  "store/deleteProduct",
  async (id, thunkApi) => {
    const result = await storeApi.storeDeleteProduct(id);
    if (result.status === 200) {
      thunkApi.dispatch(storeGetProduct());
    }
  }
);

export const storeEditProduct = createAsyncThunk(
  "store/editProduct ",
  async (data, thunkApi) => {
    // console.log("id", id);
    const result = await storeApi.storeEditProduct(data.id, data.data);
    if (result.status === 200) {
      thunkApi.dispatch(storeGetProduct());
    }
    // return result;
  }
);

export const getOrderByStore = createAsyncThunk(
  "store/getOrderByStore",
  async (id) => {
    const myStore = await storeApi.getMyStore();
    if (myStore.status === 200) {
      const result = await storeApi.getOrderByStore(myStore.data.id);
      return result;
    }
  }
);

export const storeCreateProduct = createAsyncThunk(
  "store/CreateProduct",
  async (data) => {
    const result = await storeApi.storeCreateProduct(data);
    return result;
  }
);

const storeSlice = createSlice({
  name: "store",
  initialState: {
    detailStore: {},
    loadingproduct: false,
    listProductStore: [],
    myStore: {},
    productsOfStore: [],
    listOrderOfStore: [],
  },
  reducers: {},
  extraReducers: {
    [getDetailStore.pending]: (state) => {
      state.loadingproduct = true;
    },
    [getDetailStore.rejected]: (state) => {
      state.loadingproduct = false;
    },
    [getDetailStore.fulfilled]: (state, action) => {
      state.loadingproduct = false;
      state.detailStore = action.payload.data;
    },
    [getProductStore.pending]: (state) => {
      state.loadingproduct = true;
    },
    [getProductStore.rejected]: (state) => {
      state.loadingproduct = false;
    },
    [getProductStore.fulfilled]: (state, action) => {
      state.loadingproduct = false;
      state.listProductStore = action.payload.data.data;
    },
    [getMyStore.pending]: (state) => {
      state.loadingproduct = true;
    },
    [getMyStore.rejected]: (state) => {
      state.loadingproduct = false;
    },
    [getMyStore.fulfilled]: (state, action) => {
      state.loadingproduct = false;
      state.myStore = action.payload;
    },
    [storeGetProduct.pending]: (state) => {
      state.loadingproduct = true;
    },
    [storeGetProduct.rejected]: (state) => {
      state.loadingproduct = false;
    },
    [storeGetProduct.fulfilled]: (state, action) => {
      state.loadingproduct = false;
      // console.log("productsOfStore", action.payload);
      state.productsOfStore = action.payload.data.data;
    },

    [getOrderByStore.pending]: (state) => {
      state.loadingproduct = true;
    },
    [getOrderByStore.rejected]: (state) => {
      state.loadingproduct = false;
    },
    [getOrderByStore.fulfilled]: (state, action) => {
      state.loadingproduct = false;
      state.listOrderOfStore = action.payload.data;
    },

    [storeCreateProduct.pending]: (state) => {
      state.loadingproduct = true;
    },
    [storeCreateProduct.rejected]: (state) => {
      state.loadingproduct = false;
    },
    [storeCreateProduct.fulfilled]: (state, action) => {
      state.loadingproduct = false;

      //map in here
      state.productsOfStore = action.payload.data;
    },
  },
});

export default storeSlice;
