import React, { useEffect } from "react";
import { token } from "./common";
import { Outlet, Navigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

function ProtectedRoutes() {
    const { user } = useSelector((state) => state.userReducer);
    return user.auth ? <Outlet /> : <Navigate to="/" />;
}

export default ProtectedRoutes;
