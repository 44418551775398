import { Route, Routes, BrowserRouter, useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";

import NoMatch from "./components/404/NoMatch";
import "antd/dist/antd.css";
import { Spin, Space } from "antd";
import ProtectedRoutes from "./helpers/ProtectedRoutes";
import React, { Suspense, useEffect } from "react";
import nprogress from "nprogress";
import { getMyInfo } from "./redux/reducers/user";
import Loading from "./components/Loading/Loading";
import GroupId from "./components/Group/GroupId/GroupId";
import ConfirmRegister from "./components/Auth/Register/RegisterLink/RegisterLink";

const Account = React.lazy(() => import("./view/user/account/Account"));
const Home = React.lazy(() => import("./view/home/Home"));
const ProductDetail = React.lazy(() =>
  import("./view/product/ProductDetail/ProductDetail")
);
const Order = React.lazy(() => import("./view/order/Order"));
const Cart = React.lazy(() => import("./view/cart/Cart"));
const ProductCategory = React.lazy(() =>
  import("./view/category/ProductCategory/ProductCategory")
);
const News = React.lazy(() => import("./view/news/News"));
const NewsDetail = React.lazy(() =>
  import("./view/news/NewsDetail/NewsDetail")
);
const Search = React.lazy(() => import("./view/search/Search"));
const Group = React.lazy(() => import("./view/group/Group"));

const Supplier = React.lazy(() => import("./view/supplier/Supplier"));

const Article = React.lazy(() => import("./view/article"));

function Routers() {
  // let location = useLocation();
  const dispatch = useDispatch();

  const { user, error, loading } = useSelector((state) => state.userReducer);

  useEffect(() => {
    dispatch(getMyInfo());
  }, []);

  useEffect(() => {
    nprogress.start();
    nprogress.done();
  }, []);

  return (
    <div>
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route element={<ProtectedRoutes />}>
              <Route path="/order" element={<Order />} />
              <Route path="/account/*" element={<Account />} />
              <Route path="/cart" element={<Cart />} />
            </Route>
            <Route
              path="/search/name=:name&desc=:desc&page=:page&size=:size"
              element={<Search />}
            />
            <Route path="/supplier/*" element={<Supplier/>} />
            <Route path="/news/size=10&page=:page" element={<News />} />
            <Route path="/" element={<Home />} />
            <Route path="/news/:id" element={<NewsDetail />} />
            <Route path="/product/:id" element={<ProductDetail />} />
            <Route
              path="/category/name=:name&page=:page&size=12&id=:id&:arrange=true"
              element={<ProductCategory />}
            />
            <Route path="/group/*" element={<Group />} />

            <Route path="/article/*" element={<Article />} />
            <Route path="/register-confirm" element={<ConfirmRegister />} />
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default Routers;
